export enum QuestionType {
  ADDITION = 'ADDITION',
  SUBTRACTION = 'SUBTRACTION',
  MULTIPLICATION = 'MULTIPLICATION',
  DIVISION = 'DIVISION',
  STORY = 'STORY',
  FIND_OPERATOR = 'FIND_OPERATOR',
  EXPRESSION = 'EXPRESSION',
  HIDDEN_OPERAND = 'HIDDEN_OPERAND',
}

export enum ExpressionTypes {
  ARITHMETIC = 'ARITHMETIC',
  PATTERN = 'PATTERN',
  COUNTING = 'COUNTING',
  COMPARISON = 'COMPARISON',
  FRACTION = 'FRACTION',
  MONEY = 'MONEY',
  SHAPE = 'SHAPE',
}

export enum Operator {
  ADDITION = 'ADDITION',
  SUBTRACTION = 'SUBTRACTION',
  MULTIPLICATION = 'MULTIPLICATION',
  DIVISION = 'DIVISION',
  EQUAL_TO = 'EQUAL_TO',
  LESS_THAN = 'LESS_THAN',
  GREATER_THAN = 'GREATER_THAN',
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
  COUNTING = 'COUNTING',
  NEARBY_NUMBERS = 'NEARBY_NUMBERS',
  SELECT_ITEMS = 'SELECT_ITEMS',
  MATCH_PAIRS = 'MATCH_PAIRS',
  DIMENSION_2D = '2D',
  DIMENSION_3D = '3D',
  IN_FRONT = 'IN_FRONT',
  BEHIND = 'BEHIND',
  ABOVE = 'ABOVE',
  BELOW = 'BELOW',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  SMALLER = 'SMALLER',
  BIGGER = 'BIGGER',
  THINNER = 'THINNER',
  LONGER = 'LONGER',
}

export enum OperatorToken {
  ADDITION = '+',
  SUBTRACTION = '-',
  MULTIPLICATION = 'x',
  DIVISION = '/',
  EQUAL_TO = '=',
  LESS_THAN = '<',
  GREATER_THAN = '>',
  ASCENDING = 'ASC',
  DESCENDING = 'DESC',
  IN_FRONT = 'In front of',
  BEHIND = 'Behind',
  ABOVE = 'Above',
  BELOW = 'Below',
  LEFT = 'Left of',
  RIGHT = 'Right of',
  SMALLER = 'Smaller',
  BIGGER = 'Bigger',
  THINNER = 'Thinner',
  LONGER = 'Longer',
}

export enum OperatorName {
  EQUAL_TO = 'Is equal to',
  LESS_THAN = 'Is less than',
  GREATER_THAN = 'Is more than',
  IN_FRONT = 'Is in front of',
  BEHIND = 'Is behind',
  ABOVE = 'Is above',
  BELOW = 'Is below',
  LEFT = 'Is left of',
  RIGHT = 'Is right of',
  SMALLER = 'Is smaller than',
  BIGGER = 'Is bigger than',
  THINNER = 'Is thinner than',
  LONGER = 'Is longer than',
}

export enum FractionName {
  WHOLES = 'wholes',
  HALVES = 'halves',
  THIRDS = 'thirds',
  FOURTHS = 'fourths',
}

export enum questionTypeToken {
  'ADDITION' = '+',
  'SUBTRACTION' = '-',
  'MULTIPLICATION' = 'x',
  'DIVISION' = '/',
  'GREATER_THAN' = '>',
  'LESS_THAN' = '<',
  'EQUAL_TO' = '=',
}

export enum Operands {
  FIRST = 'A',
  SECOND = 'B',
  THIRD = 'C',
  OPERATOR = 'D',
}

export const GhanaianMoney = [
  { label: '1 pesewa', value: 0.01, img: '/img/1-pesewa.jpg' },
  { label: '5 pesewas', value: 0.05, img: '/img/5-pesewa.jpg' },
  { label: '10 pesewas', value: 0.1, img: '/img/10-pesewa.jpg' },
  { label: '20 pesewas', value: 0.2, img: '/img/20-pesewa.jpg' },
  { label: '50 pesewas', value: 0.5, img: '/img/50-pesewa.jpg' },
  { label: '1 cedi', value: 1.0, img: '/img/Money_1_GHS.png' },
  { label: '2 cedis', value: 2.0, img: '/img/Money_2_GHS.png' },
  { label: '5 cedis', value: 5.0, img: '/img/Money_5_GHS.png' },
  { label: '10 cedis', value: 10.0, img: '/img/Money_10_GHS.png' },
  { label: '20 cedis', value: 20.0, img: '/img/Money_20_GHS.png' },
  { label: '50 cedis', value: 50.0, img: '/img/Money_50_GHS.png' },
  { label: '100 cedis', value: 100.0, img: '100 cedis' },
  { label: '200 cedis', value: 200.0, img: '200 cedis' },
] as Money[]

export enum GameType {
  COUNTING = 'counting',
  PATTERN = 'pattern',
  ARITHMETIC = 'arithmetic',
  COMPARISON = 'comparison',
  FRACTION = 'fraction',
  MONEY = 'money',
  SHAPE = 'shape',
  POSITION = 'position',
  MEASUREMENT = 'measurement',
}

export enum HiddenOperands {
  OPERANDA = 'A',
  OPERANDB = 'B',
  OPERANDC = 'C',
}

export enum OperandVisualTypes {
  NUMBER = 'number',
  BLOCKS = 'blocks',
}

export enum ItemVisualTypes {
  ORANGES = 'oranges',
  BLOCKS = 'blocks',
  SHAPES = 'shapes',
  IMAGES = 'images',
}

export enum Shapes {
  CIRCLE = 'circle',
  SQUARE = 'square',
  TRIANGLE = 'triangle',
  RECTANGLE = 'rectangle',
}

export enum FractionShapes {
  CIRCLE = 'circle',
  SQUARE = 'square',
  TRIANGLE = 'triangle',
  RECTANGLE = 'rectangle',
  RANDOM = 'random',
}

export enum FractionImages {
  DATA_ISLAND = '/img/data_island.jpg',
  GEOMETRY_ISLAND = '/img/geometry_island.jpg',
  NUMBERS_ISLAND = '/img/numbers_island.jpg',
  ALGEBRA_ISLAND = '/img/algebra_island.jpg',
}

export const NUMBER_OF_LEVELS = 5
export const NUMBER_OF_RELIGION_LEVELS = 5

export enum roles {
  ADMIN = 'admin',
  TEACHER = 'teacher',
  STUDENT = 'student',
  SYSTEM_ADMIN = 'system_admin',
}

export enum classes {
  MATH_B1 = 'math_b1',
  RELIGION_B1 = 'religion_b1',
}
